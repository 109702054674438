// Load Styles
import "../scss/main.scss";
import "fullpage.js";
import { Modal } from "bootstrap";
import { Loader } from "@googlemaps/js-api-loader";

const nextSlideButton = document.querySelector("a.change-active-slide");
const menuItemLinks = document.querySelectorAll("a.menu-link");
const menuToggleCheckbox = document.querySelector("input#menu-toggle");
const discoverLinks = document.querySelectorAll("a.item");
const galleryLinks = document.querySelectorAll("a.gallery-item");
const discoverModalContent = document.querySelector(
  ".modal-content.discover-content"
);
const galleryModalContent = document.querySelector(
  ".modal-content.gallery-content"
);
const modalImage = document.querySelector(".modal-image");
const discoverDetailModal = new Modal("#discover-section-detail");
const galleryDetailModal = new Modal("#gallery-image-detail");

$(function () {
  $("#fullpage").fullpage({
    anchors: [
      "home-page",
      "about-page",
      "about-2-page",
      "about-3-page",
      "discover-page",
      "location-page",
      "gallery-page",
      "contact-page",
    ],
    scrollBar: false,
    onLeave: function (index, nextIndex, direction) {
      if (nextIndex === 8) {
        nextSlideButton.classList.add("leave");
      } else {
        nextSlideButton.classList.remove("leave");
      }
      discoverDetailModal.hide();
      galleryDetailModal.hide();
    },
  });

  nextSlideButton.addEventListener("click", function () {
    $.fn.fullpage.moveSectionDown();
  });

  menuItemLinks.forEach((el) => {
    el.addEventListener("click", function () {
      menuToggleCheckbox.checked = false;
    });
  });

  discoverLinks.forEach((link) => {
    link.addEventListener("click", function () {
      const content = link.dataset.content;
      discoverModalContent.innerHTML = content;

      discoverDetailModal.show();
    });
  });

  discoverModalContent.addEventListener("click", function () {
    discoverDetailModal.hide();
  });

  galleryLinks.forEach((link) => {
    link.addEventListener("click", function () {
      const src = link.dataset.src;
      modalImage.src = src;

      galleryDetailModal.show();
    });
  });

  galleryModalContent.addEventListener("click", function () {
    galleryDetailModal.hide();
  });

  const loader = new Loader({
    apiKey: "AIzaSyCyC2iZpxnTbIXuvcKTiurHQrJJBzL8vT4",
    version: "weekly",
  });

  loader.load().then(async () => {
    await initMap();
  });
});

async function initMap() {
  const { Map, InfoWindow } = await google.maps.importLibrary("maps");
  const { Marker } = await google.maps.importLibrary("marker");

  const styles = [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#444444",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f2f2f2",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#2e308b",
        },
        {
          visibility: "on",
        },
      ],
    },
  ];

  const map = new Map(document.getElementById("map"), {
    center: { lat: -37.81254456959706, lng: 144.93699545756425 },
    zoom: 15,
    disableDefaultUI: true,
    styles,
  });

  const icon = {
    url: "./images/svg/marker.svg",
    scaledSize: new google.maps.Size(25, 25),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(0, 0),
  };

  const marker = new Marker({
    map,
    position: { lat: -37.81254456959706, lng: 144.93699545756425 },
    icon: icon,
  });

  const infowindow = new google.maps.InfoWindow({
    content: "The District Docklands",
  });

  marker.addListener("click", () => {
    infowindow.open({
      anchor: marker,
      map,
    });
  });
}

// Your app code
console.log("Development by: Evren Yeniev");
